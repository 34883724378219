.page-invoice {

    .invoice-wrapper {
        padding: 0 0 30px;
        background-color: $reverse;
    }

}

.invoice-inner {
    padding: 15px 15px 30px;
    background-color: $reverse;

    .invoice-sum {
        li {
            margin-bottom: 5px;
            padding: 10px;
            background-color: $table-bg-accent;
            border-radius: $border-radius-base;
        }
    }

    .table {
        &.table-bordered {
            border: 0;
        }

        .bg-dark {
            > th {
                border: 0;
            }
        }
    }
}

.busy {
    background-color: rgba(150,150,150,.3) !important;
    cursor: wait;
    height: 100%;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 99;
}

.busy-content {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30%;
    width: 50%;
    margin: 0% 0 0 -25%;
    font-size: 42px;
    font-weight: bold;
    color: $brand-primary;
    text-shadow: 4px 4px 2px rgba(150, 150, 150, .5);
}