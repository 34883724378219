
form.loyalty-settings {
    label {
        display:block;
        clear:both;

    }
}
.field-explanation {
	font-size: 12px;
	color: $brand-primary;
}