.z-depth-1, .wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active, .header-container, .header-container .dropdown-menu.with-arrow.panel {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2, .page-signin .card,
.page-signup .card,
.page-forgot .card {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

@media print {
  .no-print {
    display: none;
  }
}

.ui-datepicker .glyphicon,
.ui-timepicker .glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-datepicker .glyphicon.glyphicon-chevron-left:before {
  content: "\f104";
}

.ui-datepicker .glyphicon.glyphicon-chevron-right:before {
  content: "\f105";
}

.ui-timepicker .glyphicon.glyphicon-chevron-up:before {
  content: "\f106";
}

.ui-timepicker .glyphicon-chevron-down:before {
  content: "\f107";
}

.btn-flat, .ui-datepicker .btn-group .btn,
.ui-datepicker li > .btn {
  background: none;
  color: #3D4051;
  border: 1px solid transparent;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: 0.25s ease-out;
  -moz-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.btn-flat:hover, .ui-datepicker .btn-group .btn:hover,
.ui-datepicker li > .btn:hover, .btn-flat:focus, .ui-datepicker .btn-group .btn:focus,
.ui-datepicker li > .btn:focus, .btn-flat:active, .ui-datepicker .btn-group .btn:active,
.ui-datepicker li > .btn:active {
  background-color: #fafafa;
  outline: none;
  box-shadow: none;
  border-color: #fafafa;
}

.btn-flat.btn-primary, .ui-datepicker .btn-group .btn-primary.btn,
.ui-datepicker li > .btn-primary.btn {
  color: #009688;
}

.btn-flat.btn-info, .ui-datepicker .btn-group .btn-info.btn,
.ui-datepicker li > .btn-info.btn {
  color: #00BCD4;
}

.btn-flat.btn-warning, .ui-datepicker .btn-group .btn-warning.btn,
.ui-datepicker li > .btn-warning.btn {
  color: #FFCA28;
}

.btn-flat.btn-danger, .ui-datepicker .btn-group .btn-danger.btn,
.ui-datepicker li > .btn-danger.btn {
  color: #F44336;
}

.ui-datepicker table {
  margin: 0 5px;
}

.ui-datepicker table th,
.ui-datepicker table td {
  padding: 1px;
}

.ui-datepicker table th .active span,
.ui-datepicker table td .active span {
  color: #ffffff;
}

.ui-datepicker table:focus {
  outline: none;
}

.ui-timepicker td {
  padding: 1px;
}

.ui-timepicker a.btn-link {
  color: #3D4051;
}

.ui-timepicker .btn:focus {
  outline: 0;
}

.ui-typeahead > .dropdown-menu {
  right: 15px;
}

.ui-rating:hover {
  cursor: pointer;
}

.ui-rating:focus {
  outline: none;
}

.ui-rating .glyphicon .fa {
  margin-right: 2px;
}

.ui-rating .glyphicon-star,
.ui-rating .fa-star {
  color: #009688;
}

.ui-rating.ui-rating-success .glyphicon-star,
.ui-rating.ui-rating-success .fa-star {
  color: #8BC34A;
}

.ui-rating.ui-rating-info .glyphicon-star,
.ui-rating.ui-rating-info .fa-star {
  color: #00BCD4;
}

.ui-rating.ui-rating-warning .glyphicon-star,
.ui-rating.ui-rating-warning .fa-star {
  color: #FFCA28;
}

.ui-rating.ui-rating-danger .glyphicon-star,
.ui-rating.ui-rating-danger .fa-star {
  color: #F44336;
}

md-backdrop.md-dialog-backdrop {
  z-index: 1040;
}

.md-dialog-container {
  z-index: 1041;
}

md-icon.zmdi {
  font-size: 24px;
}

.ui-md-radio-group-inline md-radio-button {
  display: inline-block;
  margin: 0 10px 10px;
}

md-radio-button[disabled] .md-on {
  background-color: #ccc;
}

md-toast.md-toast-primary > .md-toast-content {
  background-color: #009688;
}

.md-datepicker-calendar-pane .md-datepicker-input-mask {
  width: auto;
}

md-toast {
  z-index: 999999 !important;
  position: fixed !important;
}

.md-primary md-tabs-wrapper md-prev-button md-icon,
.md-primary md-tabs-wrapper md-next-button md-icon {
  color: #eeeeee;
}

.angular-ui-tree {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
}

.angular-ui-tree-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  border: 1px dashed #e2edeb;
  background-color: #E6F5FD;
  border-radius: 2px;
}

.angular-ui-tree-handle {
  position: relative;
  display: block;
  margin: 5px 0;
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid #e2edeb;
  background: #fff;
  cursor: move;
  border-radius: 2px;
}

.angular-ui-tree-handle:hover .angular-ui-tree-icon-action {
  display: inline;
}

.angular-ui-tree-icon:hover {
  cursor: pointer;
}

.angular-ui-tree-icon-collapse {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 40px;
  margin: -10px 0 0 -10px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
}

.angular-ui-tree-icon-collapse:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.angular-ui-tree-icon-collapse.uncollapsed:before {
  content: '-';
}

.angular-ui-tree-icon-action {
  margin-left: 7px;
  display: none;
  color: #777777;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.angular-ui-tree-icon-action:hover {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  color: #3D4051;
}

.angular-ui-tree-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #fff;
  background-size: 60px 60px;
  background-position: 0 0,30px 30px;
}

.angular-ui-tree-nodes {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 30px;
}

.angular-ui-tree-node, .angular-ui-tree-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}

.angular-ui-tree-hidden {
  display: none;
}

.angular-ui-tree-drag {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: .8;
}

table.responsive {
  border: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  table.responsive {
    margin-bottom: 0;
  }
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 35%;
    overflow: hidden;
    overflow-x: scroll;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .pinned table {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: none;
    border-left: none;
    width: 100%;
  }
  .pinned table th, .pinned table td {
    white-space: nowrap;
  }
  .pinned td:last-child {
    border-bottom: 0;
  }
  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc;
  }
  div.table-wrapper div.scrollable {
    margin-left: 35%;
  }
  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
  }
  table.responsive td, table.responsive th {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
  table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td {
    display: none;
  }
}

.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}

.wizard a,
.tabcontrol a {
  outline: 0;
}

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

/*
    Wizard
*/
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard > .steps > ul > li {
  width: 100%;
}

@media (min-width: 600px) {
  .wizard > .steps > ul > li {
    width: 25%;
  }
}

.wizard.vertical > .steps {
  display: block;
  width: 100%;
}

@media (min-width: 600px) {
  .wizard.vertical > .steps {
    display: inline;
    float: left;
    width: 30%;
  }
}

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.wizard.vertical > .content {
  width: 100%;
}

@media (min-width: 600px) {
  .wizard.vertical > .content {
    display: inline;
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
  }
}

.wizard > .steps .number {
  font-size: 1.429em;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  border-radius: 2px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
  box-shadow: none;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #009688;
  color: #fff;
  cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #009688;
  color: #fff;
  opacity: .6;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #F44336;
  color: #fff;
}

.wizard > .content {
  background-color: #eee;
  display: block;
  margin: 0.5em;
  min-height: 25em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.wizard > .content > .body ul {
  list-style: disc !important;
}

.wizard > .content > .body ul > li {
  display: list-item;
}

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.wizard > .content > .body input {
  display: block;
  border: 1px solid #ccc;
}

.wizard > .content > .body input[type="checkbox"] {
  display: inline-block;
}

.wizard > .content > .body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}

.wizard > .content > .body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em;
}

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}

.wizard > .actions > ul > li {
  margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background-color: #009688;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  min-width: 100px;
  border-radius: 2px;
  text-align: center;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: #eee;
  color: #aaa;
}

/*
    Tabcontrol
*/
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 2px;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topleft: 2px;
  -moz-border-radius-topright: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

.ui-angular-wizard .steps {
  border: 1px solid #e1e1e1;
  margin-bottom: 3em;
  padding: 0;
  border-radius: 2px;
}

.ui-angular-wizard .steps h2 {
  font-size: 18px;
  line-height: 1;
  padding: 1.2em 1em;
  margin: 0;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
}

.ui-angular-wizard .ui-wizard-content {
  background-color: #fafafa;
  padding: 3em 1em;
}

.ui-angular-wizard .ui-wizard-actions {
  margin: 0;
  padding: 1.2em 1em;
  text-align: right;
  border-top: 1px solid #e1e1e1;
}

.steps-indicator {
  /* ---- steps quantity ---- */
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px 0 0 0;
  height: 30px;
  list-style: none;
  /* --- http://www.paulirish.com/2012/box-sizing-border-box-ftw/ ---- */
}

.steps-indicator:before {
  background-color: #E6E6E6;
  content: '';
  position: absolute;
  height: 1px;
}

.steps-indicator.steps-2:before {
  left: calc(100% / 2 / 2);
  right: calc(100% / 2 / 2);
}

.steps-indicator.steps-3:before {
  left: calc(100% / 3 / 2);
  right: calc(100% / 3 / 2);
}

.steps-indicator.steps-4:before {
  left: calc(100% / 4 / 2);
  right: calc(100% / 4 / 2);
}

.steps-indicator.steps-5:before {
  left: calc(100% / 5 / 2);
  right: calc(100% / 5 / 2);
}

.steps-indicator.steps-6:before {
  left: calc(100% / 6 / 2);
  right: calc(100% / 6 / 2);
}

.steps-indicator.steps-7:before {
  left: calc(100% / 7 / 2);
  right: calc(100% / 7 / 2);
}

.steps-indicator.steps-8:before {
  left: calc(100% / 8 / 2);
  right: calc(100% / 8 / 2);
}

.steps-indicator.steps-9:before {
  left: calc(100% / 9 / 2);
  right: calc(100% / 9 / 2);
}

.steps-indicator.steps-10:before {
  left: calc(100% / 10 / 2);
  right: calc(100% / 10 / 2);
}

.steps-indicator * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.steps-indicator li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  text-align: center;
  line-height: 15px;
}

.steps-indicator li a {
  color: #808080;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  transition: 0.25s;
  cursor: pointer;
}

.steps-indicator li a:before {
  position: absolute;
  top: -7px;
  left: calc(50% - 7px);
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #E6E6E6;
  content: '';
  transition: 0.25s;
}

.steps-indicator li a:hover {
  color: #4d4d4d;
}

.steps-indicator.steps-2 li {
  width: calc(100% / 2);
}

.steps-indicator.steps-3 li {
  width: calc(100% / 3);
}

.steps-indicator.steps-4 li {
  width: calc(100% / 4);
}

.steps-indicator.steps-5 li {
  width: calc(100% / 5);
}

.steps-indicator.steps-6 li {
  width: calc(100% / 6);
}

.steps-indicator.steps-7 li {
  width: calc(100% / 7);
}

.steps-indicator.steps-8 li {
  width: calc(100% / 8);
}

.steps-indicator.steps-9 li {
  width: calc(100% / 9);
}

.steps-indicator.steps-10 li {
  width: calc(100% / 10);
}

.steps-indicator.steps-11 li {
  width: calc(100% / 11);
}

.steps-indicator li.default {
  pointer-events: none;
}

.steps-indicator li.default a:hover {
  color: #808080;
}

.steps-indicator li.current,
.steps-indicator li.editing {
  pointer-events: none;
}

.steps-indicator li.current a:before {
  background-color: #808080;
}

.steps-indicator li.done a:before {
  background-color: #8BC34A;
}

.steps-indicator li.editing a:before {
  background-color: #009688;
}

.page-dashboard h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 30px;
}

.page-dashboard .sm-charts {
  margin: 0 auto;
}

.page-dashboard .box-stats {
  text-align: center;
}

.page-dashboard .box-stats.no-border .box-num {
  border: 0;
}

.page-dashboard .box-stats .box-desc {
  font-size: 12px;
  line-height: 26px;
  color: #A5AEB1;
  text-transform: uppercase;
}

.page-dashboard .box-stats .box-num {
  font-size: 42px;
  line-height: 80px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .page-dashboard .box-stats .box-num {
    border-right: 2px solid #EDF0F1;
  }
}

.page-dashboard .box-stats .box-title {
  font-size: 20px;
  line-height: 80px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .page-dashboard .box-stats .box-title {
    border-right: 2px solid #EDF0F1;
  }
}

.page-dashboard .metrics {
  margin-bottom: 55px;
}

.page-dashboard .metrics .metric {
  display: block;
  margin-bottom: 10px;
  font-size: 26px;
  color: #009688;
}

.page-dashboard .metrics .metric-info {
  text-transform: uppercase;
  font-size: 16px;
  color: #aaa;
}

.page-dashboard .progressbar-container {
  margin-bottom: 25px;
}

.page-dashboard .md-progressbar {
  margin-bottom: 5px !important;
  height: 10px;
}

.page-dashboard .md-progressbar > .md-container {
  background-color: #f5f5f5;
  height: 10px;
}

.page-dashboard .panel-box {
  position: relative;
  border-radius: 2px;
}

.page-dashboard .panel-box .panel-top,
.page-dashboard .panel-box .panel-bottom {
  height: 100px;
  padding: 30px 15px;
  font-size: 40px;
  line-height: 1;
}

.page-dashboard .panel-box .panel-bottom {
  border-top: 1px solid #dfdfdf;
}

.page-dashboard .panel-box .panel-info {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -12px;
  text-align: center;
}

.page-dashboard .panel-box .panel-info span {
  height: 24px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  line-height: 14px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  font-size: 11px;
  color: #777777;
  border-radius: 1em;
}

.page-dashboard .panel-box .bg-light {
  background-color: #f9f9f9;
}

.page-dashboard .axis text {
  font: 10px sans-serif;
}

.page-dashboard .chartTitle {
  font-size: 12px;
  font-weight: bold;
  text-anchor: middle;
}

.page-dashboard .axis .title {
  font-weight: bold;
  text-anchor: middle;
}

.page-dashboard .axis path,
.page-dashboard .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.page-dashboard .x.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.page-dashboard .nav .area {
  fill: lightgrey;
  stroke-width: 0px;
}

.page-dashboard .nav .line {
  fill: none;
  stroke: darkgrey;
  stroke-width: 1px;
}

.page-dashboard .viewport {
  stroke: grey;
  fill: black;
  fill-opacity: 0.3;
}

.page-dashboard .viewport .extent {
  fill: rgba(92, 184, 92, 0.7);
}

.page-dashboard .well {
  padding-top: 0px;
  padding-bottom: 0px;
}

.page-dashboard .svg-content-responsive {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}

.lt-ie9-bg {
  z-index: 11111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  color: #999;
  padding: 100px 20px;
  text-align: center;
  font-size: 26px;
}

.lt-ie9-bg a {
  color: #f5f5f5;
  border-bottom: 2px solid #fff;
}

.lt-ie9-bg a:hover {
  text-decoration: none;
}

.non-display {
  display: none;
}

.page {
  padding: 15px 30px;
}

.page > .ui-section:last-child {
  margin-bottom: 0;
}

.page-form-ele h3 {
  margin: 0;
}

label.offset {
  padding-left: 2rem !important;
}

.body-err {
  background-color: #262B33;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.page-err {
  position: relative;
  height: 100%;
}

.page-err .err-container {
  padding: 45px 10px 0;
}

@media (min-width: 768px) {
  .page-err .err-container {
    padding: 100px 0 0;
  }
}

.page-err .err {
  color: #fafafa;
}

.page-err .err h1 {
  margin-bottom: 35px;
  color: #fafafa;
  color: rgba(255, 255, 255, 0.8);
  font-size: 150px;
  font-weight: 300;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .page-err .err h1 {
    font-size: 180px;
  }
}

.page-err .err h2 {
  color: #fafafa;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .page-err .err h2 {
    font-size: 36px;
  }
}

.page-err .err-body {
  padding: 20px 10px;
}

.page-err .btn-goback {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, 0.8);
}

.page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.open .page-err .btn-goback.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.page-err .footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.body-auth {
  background-color: #EDF0F1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.btn-minimal {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border-color: #ffffff;
  border-color: rgba(255, 255, 255, 0.8);
}

.btn-minimal:hover, .btn-minimal:focus, .btn-minimal:active, .btn-minimal.active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.open .btn-minimal.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.page-signin .wrapper,
.page-signup .wrapper,
.page-forgot .wrapper {
  padding: 0 10px;
}

.page-signin .main-body,
.page-signup .main-body,
.page-forgot .main-body {
  max-width: 480px;
  margin: 10px auto;
}

.page-signin .body-inner,
.page-signup .body-inner,
.page-forgot .body-inner {
  position: relative;
  padding: 20px;
}

.page-signin .body-inner:before,
.page-signup .body-inner:before,
.page-forgot .body-inner:before {
  z-index: -1;
  content: ' ';
  line-height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #009688;
  height: 220px;
}

.page-signin .additional-info,
.page-signup .additional-info,
.page-forgot .additional-info {
  text-align: center;
  font-size: 12px;
}

.page-signin .additional-info a,
.page-signup .additional-info a,
.page-forgot .additional-info a {
  color: #fafafa;
}

.page-signin .additional-info .divider-h,
.page-signup .additional-info .divider-h,
.page-forgot .additional-info .divider-h {
  border-right: 1px solid #fafafa;
  margin: 0 15px;
}

.page-signin h1,
.page-signup h1,
.page-forgot h1 {
  font-weight: normal;
  color: #009688;
  font-size: 38px;
  margin-bottom: 40px;
}

.page-signin h1 a,
.page-signup h1 a,
.page-forgot h1 a {
  color: #009688;
}

.page-signin .card .card-content,
.page-signup .card .card-content,
.page-forgot .card .card-content {
  padding: 30px 20px;
}

.page-signin .ui-input-group .form-control,
.page-signup .ui-input-group .form-control,
.page-forgot .ui-input-group .form-control {
  color: #555555;
}

.page-signin .form-group,
.page-signup .form-group,
.page-forgot .form-group {
  position: relative;
  margin: 0;
}

.page-signin .wrapper,
.page-signup .wrapper,
.page-forgot .wrapper {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .page-signin .wrapper,
  .page-signup .wrapper,
  .page-forgot .wrapper {
    margin-top: 50px;
  }
}

.page-signin .logo,
.page-signup .logo,
.page-forgot .logo {
  font-size: 26px;
  font-weight: normal;
}

.page-signin .logo a:hover,
.page-signup .logo a:hover,
.page-forgot .logo a:hover {
  text-decoration: none;
}

.barcode-container img {
  background-color: #fff;
  padding: 7px;
  border-radius: 10px;
}

.page-profile {
  margin: -15px 0 0;
}

.page-profile .profile-header {
  position: relative;
  margin: 0 -30px 15px;
  padding: 50px 30px 90px;
  background: url("../images/background/1.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.page-profile .profile-img {
  display: inline-block;
  margin-right: 20px;
}

.page-profile .profile-img img {
  max-width: 120px;
  height: auto;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2);
}

.page-profile .profile-social {
  display: inline-block;
}

.page-profile .profile-social > a {
  margin-right: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.page-profile .profile-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 10px 30px;
  color: #fafafa;
}

.page-profile .profile-info ul {
  margin: 0;
}

.page-profile img.media-object {
  border-radius: 2px;
}

.page-invoice .invoice-wrapper {
  padding: 0 0 30px;
  background-color: #ffffff;
}

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #ffffff;
}

.invoice-inner .invoice-sum li {
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 2px;
}

.invoice-inner .table.table-bordered {
  border: 0;
}

.invoice-inner .table .bg-dark > th {
  border: 0;
}

.busy {
  background-color: rgba(150, 150, 150, 0.3) !important;
  cursor: wait;
  height: 100%;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 99;
}

.busy-content {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  width: 50%;
  margin: 0% 0 0 -25%;
  font-size: 42px;
  font-weight: bold;
  color: #009688;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 0.5);
}

.page-icons .page-header {
  border-bottom: 0;
}

.page-icons .icon-material .zmdi {
  display: block;
  height: 80px;
  font-size: 1.5em;
  line-height: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.page-icons .icon-material .col-sm-4 {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.page-icons .icon-material .col-sm-4:hover {
  color: #262B33;
}

.page-icons .icon-material .col-sm-4:hover .zmdi {
  font-size: 3em;
  color: #009688;
}

.page-features .panel {
  border: 0;
}

.page-features .feature-list-container .panel-body {
  background-color: #405161;
  padding: 30px 50px;
}

.page-features .feature-list-container .col-xs-4 {
  margin-bottom: 50px;
}

.loyalty-balance {
  padding: 1rem;
}

.loyalty-balance .md-button {
  margin-top: 1rem;
}

.loyalty-settings {
  padding: 1rem;
}

.loyalty-settings .md-button {
  margin-bottom: 2rem;
}

.loyalty-field {
  min-height: 50px;
  width: 200px !important;
}

.product-grid .product {
  height: 410px;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}

.category-products {
  padding-top: 15px;
}

.product-img {
  margin: 15px;
  max-width: 100px;
  max-height: 100px;
}

.page-form-ele .list-checkbox-radio li:nth-child(even) {
  margin-bottom: 20px;
}

.page-form-ele .list-checkbox-radio li > div {
  float: left;
  margin-right: 10px;
}

.demoslider-container {
  max-width: 600px;
  margin: auto;
}

.page-grid .grid-structure .row {
  margin-top: 10px;
}

.page-grid .grid-structure .row .widget-container {
  margin-top: 5px;
  background: #eee;
  padding: 10px 15px 12px;
  font-size: 12px;
  min-height: 0;
  border-radius: 2px;
}

.table.table-demo tr:first-child td {
  border-top: 0;
}

.table.table-demo .type-info {
  vertical-align: middle;
}

.whiteframedemoBasicUsage md-whiteframe {
  background: #fff;
  margin: 30px;
  height: 100px;
}

@media screen and (max-width: 599px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 7px;
    height: 50px;
    background-color: #c8e4fa;
  }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.4em;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 20px;
    height: 75px;
  }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.6em;
  }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 20px;
    height: 90px;
    background-color: #fcddde;
  }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 0.9em;
  }
}

@media screen and (min-width: 1200px) {
  .whiteframedemoBasicUsage md-whiteframe {
    margin: 25px;
    height: 100px;
    background-color: #F2FCE2;
  }
  .whiteframedemoBasicUsage md-whiteframe > span {
    font-size: 1em;
  }
}

.radioButtondemoMultiColumn md-divider {
  margin: 0 -15px;
}

.radioButtondemoMultiColumn div.radioDemo2 {
  margin-bottom: 20px;
}

.radioButtondemoMultiColumn h2 {
  margin-left: 15px;
}

.radioButtondemoMultiColumn p {
  width: 400px;
  margin-top: 10px;
  margin-left: 10px;
  padding-top: 10px;
  border-top: 2px solid #ddd;
}

.radioButtondemoMultiColumn .md-checked {
  background-color: #ECFAFB;
  border-radius: 2px;
}

.radioButtondemoMultiColumn md-button.md-raised, .radioButtondemoMultiColumn button.md-raised {
  width: 200px;
}

.radioButtondemoMultiColumn .row {
  border-bottom: 1px dashed #ddd;
}

.radioButtondemoMultiColumn div.row:last-child {
  border-bottom: 0px dashed #ddd;
}

.radioButtondemoMultiColumn .summary {
  width: 100%;
  padding-top: 10px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -5px;
}

.radioButtondemoMultiColumn .title {
  font-weight: bolder;
}

.radioButtondemoMultiColumn .selectedUser .md-checked {
  padding: 8px;
  width: 100px;
}

form.loyalty-settings label {
  display: block;
  clear: both;
}

.field-explanation {
  font-size: 12px;
  color: #009688;
}

.layout-boxed > .header-container .top-header {
  max-width: 1200px;
  margin: 0 auto;
}

.layout-boxed > .main-container {
  max-width: 1200px;
  margin: 0 auto;
}

.instructions {
  position: fixed;
  background: #fff;
  top: 0;
  height: 100vh;
  width: 300px;
  right: 0;
  z-index: 99999;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.instructions ul {
  max-height: 80%;
}

.body-wide.app > .main-container > .nav-container,
.body-wide.app > .main-container > .content-container,
.body-wide .header-container > .top-header .logo,
.body-wide .header-container > .top-header .top-nav {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

html {
  position: relative;
  min-height: 100%;
  height: 100%;
  background: none;
}

.app {
  position: relative;
  min-height: 100%;
  height: 100%;
}

.app > .header-container.header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.app > .header-container.header-fixed + .main-container {
  padding-top: 128px;
}

@media (min-width: 768px) {
  .app > .header-container.header-fixed + .main-container {
    padding-top: 64px;
  }
}

.app > .main-container {
  position: relative;
  min-height: 100%;
  padding-bottom: 0;
}

.app > .main-container:before {
  content: " ";
  line-height: 0;
  z-index: -2;
  position: absolute;
  display: block;
  width: 100%;
  max-width: inherit;
  top: 0;
  bottom: 0;
  background-color: #efefef;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.app > .main-container > .nav-vertical:before {
  left: 0;
}

.app > .main-container > .nav-container {
  display: none;
  z-index: 11;
  position: static;
  float: left;
  width: 220px;
  -webkit-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out;
}

@media (min-width: 768px) {
  .app > .main-container > .nav-container {
    display: block;
  }
}

.app > .main-container > .nav-container:before {
  z-index: -1;
  content: " ";
  line-height: 0;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: inherit;
  background-color: inherit;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
}

.app > .main-container > .nav-container.nav-fixed {
  position: fixed;
  top: 128px;
  bottom: 0;
  float: none;
}

@media (min-width: 768px) {
  .app > .main-container > .nav-container.nav-fixed {
    top: 64px;
  }
}

.app > .main-container > .content-container {
  overflow: hidden;
  min-height: 100%;
  margin-left: 0;
  -webkit-transition: margin-left 0.25s ease-in-out;
  -moz-transition: margin-left 0.25s ease-in-out;
  transition: margin-left 0.25s ease-in-out;
}

@media (min-width: 768px) {
  .app > .main-container > .content-container {
    margin-left: 220px;
  }
}

#nav-container ul {
  padding-left: 0;
  list-style: none;
}

#nav-container > .nav-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll !important;
}

.nav-container .md-button {
  text-align: left;
  min-width: 20px;
  text-transform: none;
  border-radius: 0;
}

.nav-container .nav {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: -blackberry-touch;
}

.nav-container .nav .nav-title {
  margin: 15px 15px 10px;
  font-weight: normal;
  font-size: 12px;
}

.nav-container .nav .nav-location {
  font-size: 20px;
  margin: 15px 15px 10px;
  color: #009688;
  font-weight: normal;
}

.nav-container .nav .nav-divider {
  margin: 10px 0 10px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.nav-container .nav > li > a > i,
.nav-container .nav > li > a > .nav-icon {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.nav-container .nav a {
  font-size: 16px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.nav-container .nav a:hover, .nav-container .nav a:focus, .nav-container .nav a:active {
  text-decoration: none;
}

.nav-container .nav > li {
  position: relative;
  margin: 0;
  text-align: left;
  font-weight: bold;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.nav-container .nav > li.open .icon-has-ul {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.nav-container .nav > li.li-small {
  font-size: 12px;
  font-weight: normal;
}

.nav-container .nav > li.li-small > a {
  padding: 10px 15px;
}

.nav-container .nav > li.li-small > a > i {
  font-size: 12px;
}

.nav-container .nav > li:first-child > a {
  border-top: 0;
}

.nav-container .nav > li > a {
  position: relative;
  white-space: nowrap;
  display: block;
  padding: 15px 15px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.nav-container .nav > li > a .badge {
  position: absolute;
  top: 16px;
  right: 8px;
}

.nav-container .nav > li > a > .icon-has-ul {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 9px;
  line-height: 19px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav-container .nav > li > .icon-has-ul-h {
  display: none;
}

.nav-container .nav ul {
  display: none;
}

.nav-container .nav ul a {
  font-size: 15px;
}

.nav-container .nav ul li {
  position: relative;
  padding: 0;
}

.nav-container .nav ul li:last-child > a {
  border-bottom: 0;
}

.nav-container .nav ul li > a {
  position: relative;
  display: block;
  padding: 13px 0 13px 25px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.nav-container .nav ul li > a:first-child {
  border-top: none;
}

.nav-container .nav ul li > a > i {
  font-size: 13px;
  line-height: 16px;
  margin-right: 16px;
  vertical-align: middle;
}

.nav-container .nav ul li > a > .badge {
  position: absolute;
  right: 8px;
  top: 12px;
}

.nav-vertical {
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.12);
}

.nav-wrapper {
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .on-canvas #header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 12;
  }
  .on-canvas #nav-container {
    display: block;
    position: fixed;
    top: 128px;
    bottom: 0;
    float: none;
  }
  .on-canvas .main-container {
    overflow: hidden;
  }
  .on-canvas .main-container #content {
    margin-left: 220px;
    margin-right: -220px;
  }
}

@media (min-width: 768px) {
  .app > .main-container > .nav-container.nav-horizontal {
    z-index: 9;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #d5d4d4;
  }
  .app > .main-container > .nav-container.nav-horizontal:before {
    border: 0;
  }
  .app > .main-container > .nav-container.nav-horizontal .nav-title,
  .app > .main-container > .nav-container.nav-horizontal .nav-divider,
  .app > .main-container > .nav-container.nav-horizontal .li-small {
    display: none !important;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed {
    border-width: 0 1px 1px 0;
    left: 0;
    right: 0;
    bottom: auto;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav {
    text-align: center;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li {
    display: inline-block;
    float: none;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li.nav-title, .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li.nav-divider {
    display: none;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed #nav > li > a {
    padding: 17.5px 28px 12.5px;
  }
  .app > .main-container > .nav-container.nav-horizontal.nav-fixed + .content-container {
    margin: 80px 0 0;
  }
}

@media (min-width: 768px) {
  .main-container > .nav-container.nav-horizontal {
    float: none;
    width: auto;
  }
  .main-container > .nav-container.nav-horizontal + .content-container {
    margin-left: 0;
  }
  .nav-horizontal {
    background-color: #eee;
  }
  .nav-horizontal .slimScrollDiv {
    overflow: visible !important;
    height: auto !important;
  }
  .nav-horizontal .slimScrollDiv .slimScrollBar,
  .nav-horizontal .slimScrollDiv .slimScrollRail {
    display: none !important;
  }
  .nav-horizontal .nav {
    overflow: visible !important;
  }
  .nav-horizontal .nav > li {
    position: relative;
    float: left;
  }
  .nav-horizontal .nav > li:hover > a {
    background-color: #e1e1e1;
    color: #3D4051;
  }
  .nav-horizontal .nav > li:hover > i {
    color: #3D4051;
  }
  .nav-horizontal .nav > li.active > a {
    background-color: transparent;
    color: #009688;
  }
  .nav-horizontal .nav > li.active > .icon-has-ul-h {
    color: #009688;
  }
  .nav-horizontal .nav > li > a {
    padding: 15px 30px;
    text-align: center;
    font-weight: normal;
  }
  .nav-horizontal .nav > li > a > i {
    margin: 0;
    font-size: 26px;
    line-height: 1;
  }
  .nav-horizontal .nav > li > a > span {
    margin: 0;
    display: block;
    font-size: 12px;
  }
  .nav-horizontal .nav > li > a .badge {
    top: 15px;
  }
  .nav-horizontal .nav > li > a > .icon-has-ul {
    display: none;
  }
  .nav-horizontal .nav > li > .icon-has-ul-h {
    display: block;
    position: absolute;
    bottom: 16px;
    right: 10px;
    font-size: 10px;
  }
  .nav-horizontal .nav > li:hover > ul {
    display: block !important;
  }
  .nav-horizontal .nav > li > ul {
    z-index: 9;
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 100%;
    width: auto;
    background-color: #e1e1e1;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .nav-horizontal .nav > li > ul li {
    white-space: nowrap;
  }
  .nav-horizontal .nav > li > ul li:hover > a, .nav-horizontal .nav > li > ul li.active > a {
    color: #009688;
  }
  .nav-horizontal .nav > li > ul li > a {
    padding: 13px 40px 13px 20px;
  }
}

@media (min-width: 768px) {
  .app.nav-collapsed-min .slimScrollDiv {
    overflow: visible !important;
    height: auto !important;
  }
  .app.nav-collapsed-min .slimScrollDiv .slimScrollBar,
  .app.nav-collapsed-min .slimScrollDiv .slimScrollRail {
    display: none !important;
  }
  .app.nav-collapsed-min #nav-container {
    width: 60px;
  }
  .app.nav-collapsed-min .content-container {
    margin-left: 60px;
  }
  .app.nav-collapsed-min #nav-wrapper {
    overflow: visible !important;
  }
  .app.nav-collapsed-min #nav {
    overflow: visible !important;
  }
  .app.nav-collapsed-min #nav > li.li-small > a > i {
    font-size: 12px;
  }
  .app.nav-collapsed-min #nav > li > a {
    padding: 15px 15px;
    text-align: center;
  }
  .app.nav-collapsed-min #nav > li > a > i {
    margin: 0;
    font-size: 22px;
  }
  .app.nav-collapsed-min #nav > li > a > span {
    display: none;
  }
  .app.nav-collapsed-min #nav > li .icon-has-ul {
    display: none;
  }
  .app.nav-collapsed-min #nav > li > ul {
    z-index: 9;
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  .app.nav-collapsed-min #nav > li:hover > ul, .app.nav-collapsed-min #nav > li.open > ul {
    display: block !important;
  }
  .app.nav-collapsed-min .nav-title {
    display: none;
  }
  .app.nav-collapsed-min .nav-location {
    display: none;
  }
  .app.nav-collapsed-min .nav-horizontal#nav-container {
    width: auto;
  }
  .app.nav-collapsed-min .nav-horizontal + .content-container {
    margin: 0;
  }
  .app.nav-collapsed-min .nav-horizontal.nav-fixed + .content-container {
    margin: 50px 0 0;
  }
  .app.nav-collapsed-min .nav-horizontal #nav > li > .icon-has-ul-h {
    display: none;
  }
  .app.nav-collapsed-min .nav-horizontal #nav > li > ul {
    left: 0;
    top: 100%;
  }
}

.nav-container:before {
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  width: 2px;
  z-index: 2;
}

.nav-container.bg-white:before {
  box-shadow: inset -1px 0 1px rgba(255, 255, 255, 0.3);
}

.nav-container.bg-white .nav a {
  color: #3D4051;
}

.nav-container.bg-white .nav .nav-title {
  color: #999;
}

.nav-container.bg-white .nav .nav-divider {
  border-color: #eeeeee;
}

.nav-container.bg-white .nav > li:hover > a {
  background-color: transparent;
  color: #009688;
}

.nav-container.bg-white .nav > li:hover > a > .icon-has-ul {
  color: #009688;
}

.nav-container.bg-white .nav > li:hover > .icon-has-ul-h {
  color: #009688;
}

.nav-container.bg-white .nav > li.active > a {
  color: #009688;
}

.nav-container.bg-white .nav > li.active > a > .icon-has-ul {
  color: #009688;
}

.nav-container.bg-white .nav > li > a:focus {
  background-color: transparent;
}

.nav-container.bg-white .nav ul {
  background-color: #f6f6f6;
}

.nav-container.bg-white .nav ul > li:hover > a, .nav-container.bg-white .nav ul > li.active > a {
  color: #009688;
}

.nav-container.bg-white.nav-vertical .nav > li.open > a {
  background-color: #f6f6f6;
  color: #009688;
}

.nav-container.bg-white.nav-vertical .nav > li.open > .icon-has-ul,
.nav-container.bg-white.nav-vertical .nav > li.open > .icon-has-ul-h {
  color: #009688;
}

.nav-container.bg-dark {
  background-color: #262B33;
}

.nav-container.bg-dark:before {
  box-shadow: inset -1px 0 1px rgba(0, 0, 0, 0.3);
}

.nav-container.bg-dark .nav a {
  color: #ccc;
  color: rgba(255, 255, 255, 0.5);
}

.nav-container.bg-dark .nav .nav-title {
  color: #666;
}

.nav-container.bg-dark .nav .nav-divider {
  border-color: #1b1f24;
}

.nav-container.bg-dark .nav > li:hover > a {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark .nav > li:hover > a > .icon-has-ul {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark .nav > li:hover > .icon-has-ul-h {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark .nav > li.active > a {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark .nav > li.active > .icon-has-ul {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark .nav > li > a:focus {
  background-color: transparent;
}

.nav-container.bg-dark .nav ul {
  background-color: #1f242a;
}

.nav-container.bg-dark .nav ul > li:hover > a, .nav-container.bg-dark .nav ul > li.active > a {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark.nav-vertical .nav > li.open > a {
  background-color: #1f242a;
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark.nav-vertical .nav > li.open > a > .icon-has-ul {
  color: rgba(255, 255, 255, 0.8);
}

.nav-container.bg-dark.nav-vertical .nav > li.open > .icon-has-ul-h {
  color: rgba(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
  .app.nav-collapsed-min .nav-container.bg-white .nav > li:hover a {
    background-color: #f6f6f6;
  }
  .app.nav-collapsed-min .nav-container.bg-dark .nav > li:hover a {
    background-color: #1f242a;
  }
}

@media (max-width: 767px) {
  .nav-horizontal .nav > li.open > a {
    color: #009688;
  }
  .nav-horizontal .nav > li.open > i {
    color: #009688;
  }
}

@media (min-width: 768px) {
  .app.nav-collapsed-min .top-header .logo {
    width: 60px;
  }
}

.app.nav-collapsed-min .top-header .logo .logo-text {
  display: none;
}

.app.nav-collapsed-min .top-header .top-nav {
  padding: 0 0 0 60px;
}

.instructions {
  position: fixed;
  background: #fff;
  top: 0;
  height: 100vh;
  width: 300px;
  right: 0;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.2);
}

.instructions .close-instructions {
  position: absolute;
  top: 0;
  padding: 1rem;
}

.instructions > ul {
  margin-top: 3rem;
  padding-right: 3rem;
  list-style-type: disc;
  overflow-y: scroll;
}

.instructions li {
  margin-bottom: 1rem;
}

.header-container .md-button {
  min-width: 66px;
}

.header-container > .top-header a {
  color: #aaa;
}

.header-container > .top-header a:hover {
  text-decoration: none;
}

.header-container > .top-header .dropdown-menu a {
  color: #3D4051;
}

.header-container > .top-header .hidden-mobile {
  display: none;
}

@media (min-width: 480px) {
  .header-container > .top-header .hidden-mobile {
    display: inline;
  }
}

.header-container > .top-header .logo {
  position: absolute;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
  -webkit-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out;
}

@media (min-width: 768px) {
  .header-container > .top-header .logo {
    display: inline-block;
    width: 220px;
    border-right: 1px solid rgba(255, 255, 255, 0.06);
  }
}

.header-container > .top-header .logo .logo-icon {
  font-size: 20px;
}

.header-container > .top-header .logo > a {
  font-size: 24px;
  vertical-align: middle;
  white-space: nowrap;
}

.header-container > .top-header .menu-button {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 46px;
  padding: 10px 12px;
  border-radius: 4px;
}

.header-container > .top-header .menu-button:hover {
  cursor: pointer;
  background-color: #00635a;
}

.header-container > .top-header .menu-button .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
}

.header-container > .top-header .menu-button .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .header-container > .top-header .menu-button {
    display: none;
  }
}

.header-container > .top-header .admin-options {
  z-index: 20;
  line-height: 20px;
}

.header-container > .top-header .skin-options .panel-body {
  min-width: 330px;
  background-color: #f9f9f9;
}

.header-container > .top-header .dropdown-menu.panel {
  padding: 0;
  white-space: nowrap;
}

.header-container > .top-header .dropdown-menu.panel .panel-heading,
.header-container > .top-header .dropdown-menu.panel .panel-footer {
  padding: 10px 15px;
}

.header-container > .top-header .dropdown-menu.panel .list-group-item {
  padding: 10px 15px;
}

.header-container > .top-header .dropdown-menu.panel .list-group-item > a {
  display: block;
}

.header-container > .top-header .dropdown-menu.panel .list-group-item > p {
  margin-bottom: 0;
}

.header-container > .top-header .dropdown-menu.panel .list-group-item > .divider {
  background-color: transparent;
}

.header-container > .top-header .dropdown-menu.panel .media .media-body {
  padding-right: 75px;
}

.header-container > .top-header .top-nav {
  width: 100%;
  height: 64px;
  padding: 64px 0 0;
  -webkit-transition: padding 0.25s ease-in-out;
  -moz-transition: padding 0.25s ease-in-out;
  transition: padding 0.25s ease-in-out;
}

@media (min-width: 768px) {
  .header-container > .top-header .top-nav {
    padding: 0 0 0 220px;
  }
}

.header-container > .top-header .top-nav > ul {
  margin-bottom: 0;
}

.header-container > .top-header .top-nav .nav-left,
.header-container > .top-header .top-nav .nav-right {
  font-size: 16px;
  line-height: 20px;
}

.header-container > .top-header .top-nav .nav-left > li,
.header-container > .top-header .top-nav .nav-right > li {
  float: left;
}

.header-container > .top-header .top-nav .nav-left > li.nav-profile > a,
.header-container > .top-header .top-nav .nav-right > li.nav-profile > a {
  padding: 17px 15px;
}

.header-container > .top-header .top-nav .nav-left > li.nav-profile .hidden-xs,
.header-container > .top-header .top-nav .nav-right > li.nav-profile .hidden-xs {
  padding-right: 8px;
}

.header-container > .top-header .top-nav .nav-left > li.nav-profile i,
.header-container > .top-header .top-nav .nav-right > li.nav-profile i {
  width: 18px;
  font-size: 18px;
  margin-right: 5px;
  line-height: 20px;
}

.header-container > .top-header .top-nav .nav-left > li > .toggle-min,
.header-container > .top-header .top-nav .nav-right > li > .toggle-min {
  display: none;
}

@media (min-width: 768px) {
  .header-container > .top-header .top-nav .nav-left > li > .toggle-min,
  .header-container > .top-header .top-nav .nav-right > li > .toggle-min {
    display: block;
  }
}

.header-container > .top-header .top-nav .nav-left > li a:hover, .header-container > .top-header .top-nav .nav-left > li a:focus,
.header-container > .top-header .top-nav .nav-right > li a:hover,
.header-container > .top-header .top-nav .nav-right > li a:focus {
  text-decoration: none;
}

.header-container > .top-header .top-nav .nav-left > li > .btn-group > a,
.header-container > .top-header .top-nav .nav-left > li > a,
.header-container > .top-header .top-nav .nav-right > li > .btn-group > a,
.header-container > .top-header .top-nav .nav-right > li > a {
  position: relative;
  display: block;
  height: 64px;
  padding: 22px 16px;
}

@media (min-width: 768px) {
  .header-container > .top-header .top-nav .nav-left > li > .btn-group > a,
  .header-container > .top-header .top-nav .nav-left > li > a,
  .header-container > .top-header .top-nav .nav-right > li > .btn-group > a,
  .header-container > .top-header .top-nav .nav-right > li > a {
    padding: 22px 20px;
  }
}

.header-container > .top-header .top-nav .nav-left > li > .btn-group > a > i,
.header-container > .top-header .top-nav .nav-left > li > a > i,
.header-container > .top-header .top-nav .nav-right > li > .btn-group > a > i,
.header-container > .top-header .top-nav .nav-right > li > a > i {
  font-size: 18px;
}

.header-container > .top-header .top-nav .nav-left > li > .btn-group > a .badge,
.header-container > .top-header .top-nav .nav-left > li > a .badge,
.header-container > .top-header .top-nav .nav-right > li > .btn-group > a .badge,
.header-container > .top-header .top-nav .nav-right > li > a .badge {
  position: absolute;
  top: 6px;
  right: 3px;
}

@media (min-width: 768px) {
  .header-container > .top-header .top-nav .nav-left > li > .btn-group > a .badge,
  .header-container > .top-header .top-nav .nav-left > li > a .badge,
  .header-container > .top-header .top-nav .nav-right > li > .btn-group > a .badge,
  .header-container > .top-header .top-nav .nav-right > li > a .badge {
    background-color: transparent;
    color: #aaa;
  }
}

.header-container > .top-header .top-nav .nav-left > li ul.dropdown-menu a:hover,
.header-container > .top-header .top-nav .nav-right > li ul.dropdown-menu a:hover {
  background-color: #009688;
  color: #fff;
}

.header-container > .top-header .top-nav .nav-left > li ul.dropdown-menu .glyphicon,
.header-container > .top-header .top-nav .nav-right > li ul.dropdown-menu .glyphicon {
  margin-right: 10px;
}

.header-container > .top-header .top-nav .search-box {
  max-width: 180px;
  padding: 12px 0;
}

.header-container > .top-header .top-nav .search-box i {
  font-size: 18px;
  line-height: 20px;
}

.header-container > .top-header .top-nav .search-box .input-group-addon {
  padding: 6px 0 6px 12px;
  border: none;
  background-color: transparent;
}

.header-container > .top-header .top-nav .search-box .form-control {
  border: none;
  background: transparent;
}

.header-container > .top-header .top-nav .search-box .input-bar {
  position: absolute;
  display: block;
  width: 90%;
  bottom: 0;
  left: 5%;
}

.header-container > .top-header .top-nav .search-box .input-bar:before,
.header-container > .top-header .top-nav .search-box .input-bar:after {
  position: absolute;
  content: '';
  height: 1px;
  width: 0;
  bottom: 1px;
  -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-container > .top-header .top-nav .search-box .input-bar:before {
  left: 50%;
}

.header-container > .top-header .top-nav .search-box .input-bar:after {
  right: 50%;
}

.header-container > .top-header .top-nav .search-box input:focus ~ .input-bar:before,
.header-container > .top-header .top-nav .search-box input:focus ~ .input-bar:after {
  width: 50%;
}

.header-container > .top-header .top-nav .nav-left {
  float: left;
  padding: 0;
}

.header-container > .top-header .top-nav .nav-left > li {
  border-right: 1px solid rgba(255, 255, 255, 0.06);
}

.header-container > .top-header .top-nav .nav-right {
  padding: 0;
}

.header-container > .top-header .top-nav .nav-right > li > a {
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.06);
}

.header-container > .top-header .top-nav .nav-right > li:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.06);
}

.header-container > .top-header .top-nav .nav-right > li:last-child .dropdown-menu.pull-right {
  right: 10px;
}

.header-container > .top-header .langs .active-flag .flag {
  margin-top: -5px;
}

.header-container > .top-header .langs .list-langs a {
  position: relative;
  padding: 8px 20px 8px 57px;
}

.header-container > .top-header .langs .list-langs a .flag {
  position: absolute;
  top: 2px;
  left: 15px;
}

.skin-check {
  position: relative;
  display: block;
}

.skin-check input[type="radio"] {
  display: none;
}

.skin-check input[type="radio"] + span:hover {
  cursor: pointer;
}

.skin-check input[type="radio"] + span {
  position: relative;
}

.skin-check input[type="radio"] + span > .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.skin-check input[type="radio"]:checked + span > .overlay {
  display: block;
}

.skin-item {
  overflow: hidden;
  display: block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.skin-item > span {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
}

.skin-item .item-header {
  height: 10px;
}

.header-container .dropdown {
  color: #3D4051;
}

.header-container .dropdown a {
  color: #3D4051;
}

.header-container .dropdown a:hover {
  color: #3D4051;
}

.header-container.bg-white {
  color: #3D4051;
}

.header-container.bg-white .input-group-addon,
.header-container.bg-white a {
  color: #3D4051;
}

.header-container.bg-white .input-group-addon:hover,
.header-container.bg-white a:hover {
  color: #3D4051;
}

.header-container.bg-white .search-box .input-bar:before,
.header-container.bg-white .search-box .input-bar:after {
  background: #3D4051;
}

.header-container.bg-dark {
  color: #fafafa;
}

.header-container.bg-dark .input-group-addon,
.header-container.bg-dark a {
  color: #fafafa;
}

.header-container.bg-dark .input-group-addon:hover,
.header-container.bg-dark a:hover {
  color: #fafafa;
}

.header-container.bg-dark .search-box .form-control {
  color: #fafafa;
}

.header-container.bg-dark .search-box .input-bar:before,
.header-container.bg-dark .search-box .input-bar:after {
  background: #fafafa;
}

.header-container.bg-primary, .header-container.bg-success, .header-container.bg-info, .header-container.bg-info-alt, .header-container.bg-warning, .header-container.bg-danger {
  color: #fafafa;
}

.header-container.bg-primary a, .header-container.bg-success a, .header-container.bg-info a, .header-container.bg-info-alt a, .header-container.bg-warning a, .header-container.bg-danger a {
  color: #fafafa;
}

.header-container.bg-primary a:hover, .header-container.bg-success a:hover, .header-container.bg-info a:hover, .header-container.bg-info-alt a:hover, .header-container.bg-warning a:hover, .header-container.bg-danger a:hover {
  color: #fafafa;
}

.header-container.bg-primary .dropdown-menu, .header-container.bg-success .dropdown-menu, .header-container.bg-info .dropdown-menu, .header-container.bg-info-alt .dropdown-menu, .header-container.bg-warning .dropdown-menu, .header-container.bg-danger .dropdown-menu {
  color: #3D4051;
}

.header-container.bg-primary .dropdown-menu a, .header-container.bg-success .dropdown-menu a, .header-container.bg-info .dropdown-menu a, .header-container.bg-info-alt .dropdown-menu a, .header-container.bg-warning .dropdown-menu a, .header-container.bg-danger .dropdown-menu a {
  color: #3D4051;
}

.header-container.bg-primary .dropdown-menu a:hover, .header-container.bg-success .dropdown-menu a:hover, .header-container.bg-info .dropdown-menu a:hover, .header-container.bg-info-alt .dropdown-menu a:hover, .header-container.bg-warning .dropdown-menu a:hover, .header-container.bg-danger .dropdown-menu a:hover {
  color: #3D4051;
}

.header-container.bg-primary .input-group-addon, .header-container.bg-success .input-group-addon, .header-container.bg-info .input-group-addon, .header-container.bg-info-alt .input-group-addon, .header-container.bg-warning .input-group-addon, .header-container.bg-danger .input-group-addon {
  color: #eeeeee;
}

.header-container.bg-primary ::-webkit-input-placeholder, .header-container.bg-success ::-webkit-input-placeholder, .header-container.bg-info ::-webkit-input-placeholder, .header-container.bg-info-alt ::-webkit-input-placeholder, .header-container.bg-warning ::-webkit-input-placeholder, .header-container.bg-danger ::-webkit-input-placeholder {
  color: #eaeaea;
}

.header-container.bg-primary :-moz-placeholder, .header-container.bg-success :-moz-placeholder, .header-container.bg-info :-moz-placeholder, .header-container.bg-info-alt :-moz-placeholder, .header-container.bg-warning :-moz-placeholder, .header-container.bg-danger :-moz-placeholder {
  color: #eaeaea;
}

.header-container.bg-primary ::-moz-placeholder, .header-container.bg-success ::-moz-placeholder, .header-container.bg-info ::-moz-placeholder, .header-container.bg-info-alt ::-moz-placeholder, .header-container.bg-warning ::-moz-placeholder, .header-container.bg-danger ::-moz-placeholder {
  color: #eaeaea;
}

.header-container.bg-primary :-ms-input-placeholder, .header-container.bg-success :-ms-input-placeholder, .header-container.bg-info :-ms-input-placeholder, .header-container.bg-info-alt :-ms-input-placeholder, .header-container.bg-warning :-ms-input-placeholder, .header-container.bg-danger :-ms-input-placeholder {
  color: #eaeaea;
}

.header-container.bg-primary .top-nav .nav-left > li > a .badge, .header-container.bg-success .top-nav .nav-left > li > a .badge, .header-container.bg-info .top-nav .nav-left > li > a .badge, .header-container.bg-info-alt .top-nav .nav-left > li > a .badge, .header-container.bg-warning .top-nav .nav-left > li > a .badge, .header-container.bg-danger .top-nav .nav-left > li > a .badge {
  color: #eaeaea;
}

.header-container.bg-primary .search-box .form-control, .header-container.bg-success .search-box .form-control, .header-container.bg-info .search-box .form-control, .header-container.bg-info-alt .search-box .form-control, .header-container.bg-warning .search-box .form-control, .header-container.bg-danger .search-box .form-control {
  color: #fafafa;
}

.header-container.bg-primary .search-box .input-bar:before,
.header-container.bg-primary .search-box .input-bar:after, .header-container.bg-success .search-box .input-bar:before,
.header-container.bg-success .search-box .input-bar:after, .header-container.bg-info .search-box .input-bar:before,
.header-container.bg-info .search-box .input-bar:after, .header-container.bg-info-alt .search-box .input-bar:before,
.header-container.bg-info-alt .search-box .input-bar:after, .header-container.bg-warning .search-box .input-bar:before,
.header-container.bg-warning .search-box .input-bar:after, .header-container.bg-danger .search-box .input-bar:before,
.header-container.bg-danger .search-box .input-bar:after {
  background: #fafafa;
}

.header-container > .top-header .logo {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .header-container > .top-header .logo {
    border: 0;
  }
}

.header-container > .top-header .logo.bg-white {
  color: #262B33;
}

.header-container > .top-header .logo.bg-white a {
  color: #262B33;
}

.header-container > .top-header .logo.bg-white a:hover {
  color: #262B33;
}

.header-container > .top-header .logo.bg-white + .menu-button .icon-bar {
  background-color: #3D4051;
}

.header-container > .top-header .logo.bg-white + .menu-button:hover .icon-bar {
  background-color: #ffffff;
}

.header-container > .top-header .logo.bg-dark, .header-container > .top-header .logo.bg-primary, .header-container > .top-header .logo.bg-success, .header-container > .top-header .logo.bg-info, .header-container > .top-header .logo.bg-info-alt, .header-container > .top-header .logo.bg-warning, .header-container > .top-header .logo.bg-danger {
  color: #fafafa;
}

.header-container > .top-header .logo.bg-dark a, .header-container > .top-header .logo.bg-primary a, .header-container > .top-header .logo.bg-success a, .header-container > .top-header .logo.bg-info a, .header-container > .top-header .logo.bg-info-alt a, .header-container > .top-header .logo.bg-warning a, .header-container > .top-header .logo.bg-danger a {
  color: #fafafa;
}

.header-container > .top-header .logo.bg-dark a:hover, .header-container > .top-header .logo.bg-primary a:hover, .header-container > .top-header .logo.bg-success a:hover, .header-container > .top-header .logo.bg-info a:hover, .header-container > .top-header .logo.bg-info-alt a:hover, .header-container > .top-header .logo.bg-warning a:hover, .header-container > .top-header .logo.bg-danger a:hover {
  color: #fafafa;
}

.preloaderbar {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  margin-bottom: -4px;
}

.preloaderbar .bar {
  position: absolute;
  width: 100%;
  height: 0;
  text-indent: -9999px;
  background-color: #23b7e5;
}

.preloaderbar .bar:before {
  position: absolute;
  right: 50%;
  left: 50%;
  height: 4px;
  background-color: inherit;
  content: "";
}

.preloaderbar.active {
  -webkit-animation: changebar 2.25s infinite 0.75s;
  -moz-animation: changebar 2.25s infinite 0.75s;
  animation: changebar 2.25s infinite 0.75s;
}

.preloaderbar.active .bar {
  -webkit-animation: changebar 2.25s infinite;
  -moz-animation: changebar 2.25s infinite;
  animation: changebar 2.25s infinite;
}

.preloaderbar.active .bar:before {
  -webkit-animation: movingbar 0.75s infinite;
  -moz-animation: movingbar 0.75s infinite;
  animation: movingbar 0.75s infinite;
}

@-webkit-keyframes movingbar {
  0% {
    right: 50%;
    left: 50%;
  }
  99.9% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}

@-moz-keyframes movingbar {
  0% {
    right: 50%;
    left: 50%;
  }
  99.9% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}

@keyframes movingbar {
  0% {
    right: 50%;
    left: 50%;
  }
  99.9% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}

@-webkit-keyframes changebar {
  0% {
    background-color: #8BC34A;
  }
  33.3% {
    background-color: #8BC34A;
  }
  33.33% {
    background-color: #FFCA28;
  }
  66.6% {
    background-color: #FFCA28;
  }
  66.66% {
    background-color: #F44336;
  }
  99.9% {
    background-color: #F44336;
  }
}

@-moz-keyframes changebar {
  0% {
    background-color: #8BC34A;
  }
  33.3% {
    background-color: #8BC34A;
  }
  33.33% {
    background-color: #FFCA28;
  }
  66.6% {
    background-color: #FFCA28;
  }
  66.66% {
    background-color: #F44336;
  }
  99.9% {
    background-color: #F44336;
  }
}

@keyframes changebar {
  0% {
    background-color: #8BC34A;
  }
  33.3% {
    background-color: #8BC34A;
  }
  33.33% {
    background-color: #FFCA28;
  }
  66.6% {
    background-color: #FFCA28;
  }
  66.66% {
    background-color: #F44336;
  }
  99.9% {
    background-color: #F44336;
  }
}

body {
  background: #efefef;
}

.body-auth.app > .main-container:before,
.body-err.app > .main-container:before {
  background: none;
}

@media (min-width: 768px) {
  .body-auth .page-signin,
  .body-auth .page-signup,
  .body-auth .page-forgot,
  .body-auth .page-err,
  .body-err .page-signin,
  .body-err .page-signup,
  .body-err .page-forgot,
  .body-err .page-err {
    margin-right: 220px;
  }
}

@media (min-width: 768px) {
  .body-auth.nav-collapsed-min .page-signin,
  .body-auth.nav-collapsed-min .page-signup,
  .body-auth.nav-collapsed-min .page-forgot,
  .body-auth.nav-collapsed-min .page-err,
  .body-err.nav-collapsed-min .page-signin,
  .body-err.nav-collapsed-min .page-signup,
  .body-err.nav-collapsed-min .page-forgot,
  .body-err.nav-collapsed-min .page-err {
    margin-right: 60px;
  }
}

@media (min-width: 768px) {
  .body-auth .nav-horizontal + .content-container .page-signin,
  .body-auth .nav-horizontal + .content-container .page-signup,
  .body-auth .nav-horizontal + .content-container .page-forgot,
  .body-auth .nav-horizontal + .content-container .page-err,
  .body-err .nav-horizontal + .content-container .page-signin,
  .body-err .nav-horizontal + .content-container .page-signup,
  .body-err .nav-horizontal + .content-container .page-forgot,
  .body-err .nav-horizontal + .content-container .page-err {
    margin-right: 0;
  }
}

.body-wide #header,
.body-wide #nav-container {
  display: none;
}

.body-wide > .main-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.body-wide #content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 0;
}

.body-wide.on-canvas .main-container #content {
  margin-left: 0;
  margin-right: 0;
}
