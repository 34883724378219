.product-grid {

	.product {
		height: 410px;
	}

}

.row-striped:nth-of-type(odd){
  background-color: #efefef;
}

.row-striped:nth-of-type(even){
  background-color: #ffffff;
}

.category-products {
	padding-top: 15px;
}

.product-img {
	margin: 15px;
	max-width: 100px;
	max-height: 100px;
}
