.page-dashboard {

    h3 {
        font-size: 22px;
        font-weight: normal;
        line-height: 1;
        margin: 0 0 30px;
    }

    .sm-charts {
        margin: 0 auto;
    }

    .box-stats {
        text-align: center;
        &.no-border {
            .box-num {
                border: 0;
            }
        }
        .box-desc {
            font-size: $font-size-small;
            line-height: 26px;
            color: $skin-gray-dark;
            text-transform: uppercase;
        }
        .box-num {
            font-size: 42px;
            line-height: 80px;
            font-weight: 300;
            @media screen and (min-width: $screen-sm-min) {
                border-right: 2px solid $skin-gray;
            }
        }
        .box-title {
            font-size: 20px;
            line-height: 80px;
            font-weight: 300;
            @media screen and (min-width: $screen-sm-min) {
                border-right: 2px solid $skin-gray;
            }
        }
    }

    .metrics {
        margin-bottom: 55px;

        .metric {
            display: block;
            margin-bottom: 10px;
            font-size: 26px;
            color: $brand-primary;
        }

        .metric-info {
            text-transform: uppercase;
            font-size: 16px;
            color: #aaa;
        }
    }

    .progressbar-container {
        margin-bottom: 25px;
    }

    .md-progressbar {
        margin-bottom: 5px !important;
        height: 10px;
    }

    .md-progressbar>.md-container {
        background-color: #f5f5f5;
        height: 10px;
    }

    //
    .panel-box {
        position: relative;
        border-radius: $border-radius-large;

        .panel-top,
        .panel-bottom {
            height: 100px;
            padding: 30px 15px;
            font-size: 40px;
            line-height: 1;
        }

        .panel-bottom {
            border-top: 1px solid #dfdfdf;
        }

        .panel-info {
            position: absolute;
            width: 100%;
            top: 50%;
            margin-top: -12px;
            text-align: center;

            span {
                height: 24px;
                display: inline-block;
                padding: 4px 10px;
                text-transform: uppercase;
                line-height: 14px;
                background-color: #fff;
                border: 1px solid #dfdfdf;
                font-size: 11px;
                color: $text-muted;
                border-radius: 1em;
            }
        }

        .bg-light {
            background-color: #f9f9f9;
        }
    }

    .axis text {
      font: 10px sans-serif;
    }
    .chartTitle {
      font-size: 12px;
      font-weight: bold;
      text-anchor: middle;
    }
    .axis .title {
      font-weight: bold;
      text-anchor: middle;
    }
    .axis path,
    .axis line {
      fill: none;
      stroke: #000;
      shape-rendering: crispEdges;
    }
    .x.axis path {
      fill: none;
      stroke: #000;
      shape-rendering: crispEdges;
    }
    .nav .area {
      fill: lightgrey;
      stroke-width: 0px;
    }
    .nav .line {
      fill: none;
      stroke: darkgrey;
      stroke-width: 1px;
    }
    .viewport {
      stroke: grey;
      fill: black;
      fill-opacity: 0.3;
    }
    .viewport .extent {
      fill: rgba(92, 184, 92, .7);
    }
    .well {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .svg-content-responsive {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
    }

}
