.loyalty-balance {
    padding: 1rem;
}

.loyalty-balance .md-button {
    margin-top: 1rem;
}

.loyalty-settings {
    padding: 1rem;
}

.loyalty-settings .md-button {
    margin-bottom: 2rem;
}

.loyalty-field {
	min-height:50px;
	width: 200px !important;
}